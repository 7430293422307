// api.js

export const getProperties = async (params) => {
  try {

    // eslint-disable-next-line no-debugger
    debugger;
    const queryParams = new URLSearchParams(params).toString();
    const response = await fetch(`https://eh66w4x2x3.execute-api.us-east-1.amazonaws.com/Prod/search?${queryParams}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener propiedades:', error);
    throw error;
  }
};

export const getRegiones = async () => {
  try {
    const response = await fetch('https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/regiones', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener regiones:', error);
    throw error;
  }
};

export const getComunas = async () => {
  try {
    const response = await fetch('https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/comunas', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener las comunas:', error);
    throw error;
  }
};

export const getTipoOperacion = async () => {
  try {
    const response = await fetch('https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/tipo_operacion', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener los tipos de operación:', error);
    throw error;
  }
};