<!-- src/App.vue -->

<script setup>
import { SignedIn, SignedOut, SignInButton, UserButton } from 'vue-clerk'
</script>
<template>
 

  
  

  <div id="app">
    <!-- Puedes agregar aquí tu Navbar si deseas que aparezca en todas las páginas -->
     <!-- Navbar -->
     <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <!-- Logo -->
    <a class="navbar-brand" href="#">
      <img 
        src="./assets/ubbi-logo.png" 
        alt="Logo Ubbi" 
        class="d-inline-block align-text-top">
    </a>
    <!-- Botón de Toggle para dispositivos móviles -->
    <button 
      class="navbar-toggler" 
      type="button" 
      data-bs-toggle="collapse" 
      data-bs-target="#navbarNav" 
      aria-controls="navbarNav" 
      aria-expanded="false" 
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Enlaces del Navbar -->
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto align-items-center">
        <!-- Botón "¿Quieres publicar?" -->
        <li class="nav-item">
          <a class="btn btn-custom ms-2" href="#" role="button">
            ¿Quieres publicar?
          </a>
        </li>
        <li class="nav-item">
          <SignedOut>
            <SignInButton />
          </SignedOut>
          <SignedIn>
            <UserButton />
          </SignedIn>
        </li>
        
        
      </ul>
    </div>
  </div>
</nav>


    <!-- Fin del Navbar -->
    <router-view />
    <!-- Puedes agregar aquí tu Footer si deseas que aparezca en todas las páginas -->
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.navbar-brand img {
  margin-right: 10px;
  height: 60px;
}
/* Importar Bootstrap CSS */
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Estilos globales personalizados */
body {
  background-color: #f8f9fa;
}

.btn-custom {
  background-color: #181717 !important;
  color: #fff !important;
  border: none;
}



</style>
