<template>
  <div id="app">
    <!-- Carrusel en el Encabezado -->
    <div class="carousel-container mb-5">
      <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel">
        <!-- Indicadores del Carrusel -->
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1">
          </button>
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2">
          </button>
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3">
          </button>
        </div>

        <!-- Elementos del Carrusel -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../assets/slide/1.jpg"
              class="d-block w-100"
              alt="Slide 1">
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide/2.jpg"
              class="d-block w-100"
              alt="Slide 2">
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide/3.jpg"
              class="d-block w-100"
              alt="Slide 3">
          </div>
        </div>

        <!-- Controles del Carrusel -->
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#headerCarousel"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#headerCarousel"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Siguiente</span>
        </button>
      </div>
    </div>
    <!-- Fin del Carrusel -->

    <div class="container mt-5">
      <!-- Formulario de Filtros -->
      <form @submit.prevent="applyFilters" class="mb-4">
        <div class="row g-3">
          <!-- Filtro de Ubicación (Región y Comuna) -->
          <div class="col-md-3 position-relative">
            <label for="location" class="form-label">Región o Comuna</label>
            <input
              type="text"
              v-model="selectedLocation"
              id="location"
              class="form-control"
              @input="onLocationInput"
              @blur="onLocationBlur"
              @focus="onLocationFocus"
              autocomplete="off"
            />
            <!-- Lista de sugerencias -->
            <ul v-if="showLocationSuggestions" class="list-group position-absolute w-100 autocomplete-dropdown">
              <li
                class="list-group-item list-group-item-action"
                v-for="location in filteredLocations"
                :key="location.key"
                @mousedown.prevent="selectLocation(location)"
              >
                {{ location.descripcion }}
                <span class="badge bg-secondary float-end">{{ location.type }}</span>
              </li>
            </ul>
          </div>

          <!-- Filtro Operación -->
          <div class="col-md-3">
            <label for="operacion" class="form-label">Operación</label>
            <select v-model="selectedOperacion" id="operacion" class="form-select">
              <option value="">Seleccionar operación</option>
              <option
                v-for="operacion in operaciones"
                :key="operacion.valor"
                :value="operacion.valor"
              >
                {{ operacion.descripcion }}
              </option>
            </select>
          </div>

        </div>

        <div class="mt-3">
          <button type="submit" class="btn btn-primary">Buscar</button>
          <button type="button" class="btn btn-secondary ms-2" @click="resetFilters">Resetear Filtros</button>
        </div>
      </form>

      <!-- Mensaje de error -->
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <!-- Estado de carga -->
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>

      <!-- Resultados de la búsqueda -->
      <div v-if="!loading && !error">
        <!-- Sección de Selector de Cantidad por Página y Resultados -->
        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="p-result">{{ total }} resultados en la búsqueda.</p>
          <div class="d-flex align-items-center">
            <label for="itemsPerPage" class="me-2 mb-0">Propiedades por página:</label>
            <select id="itemsPerPage" v-model.number="itemsPerPage" @change="changeItemsPerPage" class="form-select w-auto">
              <option v-for="option in perPageOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <!-- Lista de Propiedades con 4 por fila -->
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4 propiedades-listado">
          <div class="col mb-3" v-for="propiedad in paginatedProperties" :key="propiedad.id">
            <div class="card tarjeta-propiedad h-100">
              <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                <div class="imagen-propiedad position-relative">
                  <div class="tipo-operacion badge bg-primary position-absolute top-0 end-0 m-2">
                    {{ propiedad.transaction_type }}
                  </div>
                  <img
                    :src="propiedad.images[0]"
                    class="card-img-top"
                    :alt="'Imagen de ' + propiedad.title">
                  <div class="precio-propiedad badge bg-success position-absolute bottom-0 start-0 m-2">
                    {{ propiedad.currency }} {{ formatPrice(propiedad.price) }}
                  </div>
                </div>
              </router-link>
              <div class="card-body">
                <h5 class="card-title">
                  <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                    {{ propiedad.title }}
                  </router-link>
                </h5>
                <div class="tipo-propiedad fw-bold">
                  {{ propiedad.property_type }}
                </div>

                <!-- Información de la Propiedad -->
                <div class="info-propiedad">
                  <p>
                    <strong>{{ propiedad.rooms }}</strong> Dormitorios |
                    <strong>{{ propiedad.bathrooms }}</strong> Baños
                  </p>
                  <p>
                    {{ propiedad.floor_area }} m² totales |
                    {{ propiedad.parking }} Estacionamientos
                  </p>
                  <p>
                    <strong>Comuna:</strong> {{ propiedad.city }}
                  </p>
                </div>
                <div class="agente-propiedad">
                  <span>Agente: {{ propiedad.agent_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Controles de Paginación -->
        <nav aria-label="Page navigation example" v-if="totalPages > 1">
          <ul class="pagination justify-content-center">
            <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a class="page-link" href="#" aria-label="Anterior" @click.prevent="changePage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li
              v-for="page in paginationRange"
              :key="page"
              :class="['page-item', { active: currentPage === page }, { disabled: page === '...' }]">
              <a
                v-if="page !== '...'"
                class="page-link"
                href="#"
                @click.prevent="changePage(page)">
                {{ page }}
              </a>
              <span v-else class="page-link disabled">...</span>
            </li>
            <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a class="page-link" href="#" aria-label="Siguiente" @click.prevent="changePage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { getProperties, getRegiones, getComunas, getTipoOperacion } from '../services/api'; // Importamos las funciones de api.js

export default {
  name: 'HomePage',
  data() {
    return {
      properties: [],
      loading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 12,
      perPageOptions: [12, 24, 48],
      total: 0,
      tiposPropiedad: [],
      regiones: [],
      comunas: [],
      operaciones: [],
      selectedTipoPropiedad: '',
      selectedOperacion: '',
      selectedLocation: '',
      selectedLocationType: '',
      selectedLocationValue: '',
      locations: [],
      filteredLocations: [],
      showLocationSuggestions: false,
    };
  },
  computed: {
    isAuthenticated() {
      return !!this.$clerk.user;
    },
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    paginatedProperties() {
      return this.properties;
    },
    paginationRange() {
      const range = [];
      const delta = 2;
      const start = Math.max(2, this.currentPage - delta);
      const end = Math.min(this.totalPages - 1, this.currentPage + delta);
      range.push(1);
      if (start > 2) range.push('...');
      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      if (end < this.totalPages - 1) range.push('...');
      if (this.totalPages > 1) range.push(this.totalPages);
      return range;
    },
  },
  methods: {
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    fetchFilteredProperties() {
      this.loading = true;
      this.error = null;
      
      const params = {
        page: this.currentPage,
        page_size: this.itemsPerPage,
      };

      // Solo añadir el tipo de propiedad si tiene un valor
      if (this.selectedTipoPropiedad) {
        params.property_type = this.selectedTipoPropiedad;
      }

      // Solo añadir el tipo de operación si tiene un valor
      if (this.selectedOperacion) {
        params.transaction_type = this.selectedOperacion;
      }

      // Si se selecciona una ubicación, añádelo como parámetro
      if (this.selectedLocation && this.selectedLocationType) {
        if (this.selectedLocationType === 'Región') {
          params.state = this.selectedLocationValue;
        } else if (this.selectedLocationType === 'Comuna') {
          params.city = this.selectedLocationValue;
        }
      }

      console.log('Parámetros enviados a la API:', params);  // Verifica los parámetros

      getProperties(params)
        .then((data) => {
          if (data.properties) {
            console.log('Propiedades Encontradas:', data.properties); 
            this.properties = data.properties;
            this.properties = data.properties.map((propiedad) =>
              this.processProperty(propiedad)
            );
            this.total = data.total;
            this.extractFilterOptions(data.properties);
          }
        })
        .catch((err) => {
          console.error(err);
          this.error = 'Error al obtener propiedades';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Procesa cada propiedad para mapear los campos necesarios
    processProperty(propiedad) {
      const processed = {
        id: propiedad.id,
        title: propiedad.title,
        description: propiedad.description,
        price: propiedad.price,
        currency: propiedad.currency,
        property_type:
          propiedad.property_type ||
          this.getAttributeValue(propiedad.attributes, 'PropertyType'),
        transaction_type:
          propiedad.transaction_type ||
          this.getAttributeValue(propiedad.attributes, 'TransactionType'),
        rooms: this.getAttributeValue(propiedad.attributes, 'Rooms'),
        bathrooms: this.getAttributeValue(propiedad.attributes, 'Bathrooms'),
        floor_area: this.getAttributeValue(propiedad.attributes, 'FloorArea'),
        plot_area: this.getAttributeValue(propiedad.attributes, 'PlotArea'),
        year: this.getAttributeValue(propiedad.attributes, 'Year'),
        parking: this.getAttributeValue(propiedad.attributes, 'Parking'),
        cellar: this.getAttributeValue(propiedad.attributes, 'Cellar'),
        orientation: this.getAttributeValue(propiedad.attributes, 'Orientation'),
        is_furnished: this.getAttributeValue(propiedad.attributes, 'IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : '',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : '',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : '',
        location: propiedad.location,
        city: propiedad.city || (propiedad.location ? propiedad.location.city : ''),
        images: propiedad.pictures.map((pic) => pic.source),
      };

      return processed;
    },// Obtiene el valor de un atributo por su nombre
    getAttributeValue(attributes, name) {
      const attr = attributes.find((attr) => attr.name === name);
      return attr ? attr.value : '';
    },
    extractFilterOptions(properties) {
      const tiposSet = new Set();
      properties.forEach((prop) => {
        if (prop.property_type) tiposSet.add(prop.property_type);
      });
      this.tiposPropiedad = Array.from(tiposSet).sort();
    },
    applyFilters() {
      this.currentPage = 1;
      this.fetchFilteredProperties();
    },
    resetFilters() {
      this.selectedTipoPropiedad = '';
      this.selectedOperacion = '';
      this.selectedLocation = '';
      this.selectedLocationType = '';
      this.selectedLocationValue = '';
      this.applyFilters();
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.fetchFilteredProperties();
    },
    changeItemsPerPage() {
      this.currentPage = 1;
      this.fetchFilteredProperties();
    },
    onLocationInput() {
      if (this.selectedLocation.length > 0) {
        const searchTerm = this.selectedLocation.toLowerCase();
        this.filteredLocations = this.locations.filter((location) =>
          location.descripcion.toLowerCase().includes(searchTerm)
        );
        this.showLocationSuggestions = this.filteredLocations.length > 0;
      } else {
        this.filteredLocations = [];
        this.showLocationSuggestions = false;
        this.selectedLocationType = '';
        this.selectedLocationValue = '';
      }
    },
    selectLocation(location) {
      this.selectedLocation = location.descripcion;
      this.selectedLocationType = location.type;
      this.selectedLocationValue = location.descripcion;
      this.filteredLocations = [];
      this.showLocationSuggestions = false;
    },
    onLocationBlur() {
      setTimeout(() => {
        this.showLocationSuggestions = false;
      }, 100);
    },
    onLocationFocus() {
      if (this.filteredLocations.length > 0) {
        this.showLocationSuggestions = true;
      }
    },
  },
  mounted() {
    Promise.all([getRegiones(), getComunas(), getTipoOperacion()])
      .then(([regionesData, comunasData, operacionesData]) => {
        this.regiones = regionesData;
        this.comunas = comunasData;
        this.operaciones = operacionesData;
        this.locations = [...this.regiones, ...this.comunas];
        this.fetchFilteredProperties();
      })
      .catch((err) => {
        console.error('Error al cargar datos iniciales', err);
        this.fetchFilteredProperties();
      });
  },
};
</script>

<style scoped>
/* Estilos para el Navbar */
.navbar-brand img {
  margin-right: 10px;
  height: 60px;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  color: #555;
}

.navbar-nav .nav-link:hover {
  color: #0d6efd;
}

/* Estilos para el Carrusel */
.carousel-container {
  width: 80%;
  margin: 0 auto;
  height: 340px;
  overflow: hidden;
  border-radius: 10px;
}

.carousel-container .carousel-item img {
  height: 340px;
  object-fit: cover;
}

/* Ajustes para el formulario de filtros */
form {
  background-color: #e9ebed;
  padding: 20px;
  border-radius: 5px;
  border: solid 2px #cbcdcf;
}

form .form-label {
  font-weight: 500;
}

form button {
  min-width: 100px;
}

/* Estilos para la lista de propiedades */
.propiedades-listado {
  /* Bootstrap grid handles the layout */
}

.tarjeta-propiedad {
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
}

.tarjeta-propiedad:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.imagen-propiedad {
  position: relative;
}

.tipo-operacion {
  /* Additional styles if needed */
}

.precio-propiedad {
  /* Additional styles if needed */
}

.tipo-propiedad {
  color: orange;
  font-weight: bold;
  text-align: left;
}

.p-result {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.card-img, .card-img-top {
  height: 260px;
}

.card-title a {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: #585858;
  text-align: left;
  font-size: 1rem;
}

.tipo-propiedad.fw-bold {
  text-align: left;
  color: #585858;
}

.info-propiedad p {
  text-align: left;
  color: #585858;
  margin-bottom: 0;
}

.agente-propiedad {
  margin-top: 12px;
  font-size: 12px;
  text-align: left;
}

div#app {
  background: #f8f9fa;
}

img.card-img-top {
  object-fit: cover;
}

h5.card-title {
  float: left;
  text-align: left;
  text-transform: uppercase;
}

/* Estilos para la paginación */
.pagination {
  margin-top: 20px;
}

.pagination .page-link {
  cursor: pointer;
}

/* Estilos para el selector de cantidad por página */
#itemsPerPage {
  width: 100px;
}

/* Responsividad */
@media (max-width: 576px) {
  .d-flex.justify-content-between {
    flex-direction: column;
    align-items: flex-start;
  }
  #itemsPerPage {
    width: 100%;
    margin-top: 10px;
  }

  form .row.g-3 {
    flex-direction: column;
  }

  form .col-md-3 {
    width: 100%;
  }
}

/* Ajustes adicionales para el carrusel */
.carousel-container .carousel-indicators button {
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-container .carousel-control-prev-icon,
.carousel-container .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-container .carousel-control-prev-icon:hover,
.carousel-container .carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-control-next, .carousel-control-prev {
  width: 5% !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 5rem;
}

/* Estilos para el botón Buscar */
button.btn.btn-primary {
  background: #181717;
  border: solid 1px #181717;
}

/* Estilos para el autocompletado de ubicación */
.autocomplete-dropdown {
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.position-relative {
  position: relative;
}
</style>
